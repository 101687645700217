<div *ngIf="!fetchingData else loadingTemplate" [formGroup]="formGroup">
  <div class="row separator">
    <div class="col-12 card">

      <div class="row px-0" [formGroup]="formGroup" *ngIf="isSuperUserOrStaff">
        <div class="col-6 px-2 mb-3">
          <app-select-input id="clinic_id" label="Clínica" [formGroup]="formGroup" [items]="clinicList"
                            [hideSelected]="true" [addTag]="false" [multiple]="false" [clearable]="false"
                            [searchable]="true"
                            (change)="setClinic($event)">
          </app-select-input>
        </div>
      </div>

      <div class="row px-0" [formGroup]="patientFormGroup">
        <div class="col-12 col-md-6 px-2">
          <div class="search">
            <app-select-input id="name" label="Paciente" [formGroup]="patientFormGroup" [items]="patientList"
                              [hideSelected]="true" [addTag]="false" [multiple]="false" [clearable]="false"
                              [searchable]="true"
                              (onValueChange)="setPatientForm($event)">
            </app-select-input>
          </div>
        </div>

        <div class="col-12 col-md-4 px-2 ">
          <app-basic-input id="phone_number" label="Telefone" controlName="phone_number" [formGroup]="patientFormGroup">
          </app-basic-input>

          <div class="px-2 col-1 mb-0 text-center whatsapp" *ngIf="patientFormGroup.get('phone_number')?.value">
            <a class="whatsapp-link" target="_blank"
               href="https://wa.me/{{patientFormGroup.get('phone_number')?.value}}">
              <span class="icon icon-Icon-awesome-whatsapp"></span>
            </a>
          </div>
        </div>

        <div class="col-12 col-md-2 d-flex justify-content-center" *ngIf="patientFormGroup.get('name')?.value">
          <button class="btn btn-link-primary" (click)="viewExams()">Ver exames</button>
        </div>
      </div>

      <ngb-accordion #acc="ngbAccordion">
        <ngb-panel id="address-accordion">
          <ng-template ngbPanelTitle>
            <span>Endereço de entrega
              <i class="icon-Icon-awesome-caret-down" *ngIf="!acc.isExpanded('address-accordion')"></i>
              <i class="icon-Icon-awesome-caret-down indicator-reverse" *ngIf="acc.isExpanded('address-accordion')"></i>
            </span>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="row p-0 m-0 mt-3">
              <div class="px-2 col-sm-2 mt-3">
                <app-basic-input id="zipcode" [formGroup]="addressFormGroup" label="CEP" controlName="zipcode">
                </app-basic-input>
              </div>

              <div class="px-2 col-sm-8 mt-3">
                <app-basic-input id="address" [formGroup]="addressFormGroup" label="Endereço" controlName="address">
                </app-basic-input>
              </div>

              <div class="px-2 col-sm-2 mt-3">
                <app-basic-input id="address_number" [formGroup]="addressFormGroup" label="Número" controlName="number">
                </app-basic-input>
              </div>

              <div class="px-2 col-sm-3 mt-3">
                <app-basic-input id="complement" [formGroup]="addressFormGroup" label="Complemento"
                                 controlName="complement">
                </app-basic-input>
              </div>

              <div class="px-2 col-sm-3 mt-3">
                <app-basic-input id="neighborhood" [formGroup]="addressFormGroup" label="Bairro"
                                 controlName="neighborhood">
                </app-basic-input>
              </div>

              <div class="px-2 col-sm-3 mt-3">
                <app-basic-input id="city" [formGroup]="addressFormGroup" label="Cidade"
                                 controlName="city"></app-basic-input>
              </div>

              <div class="px-2 col-sm-3 mt-3">
                <app-basic-input id="state" [formGroup]="addressFormGroup" label="Estado" controlName="state">
                </app-basic-input>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>

      <div class="row p-0 m-0 mt-3">
        <div class="col-3 p-0 m-0 px-2">
          <label class="field__title">Oxímetro</label>
          <ng-select [ngClass]="formGroup.get('oximeter_id')!.errors ? 'error' : 'custom'" [formGroup]="formGroup"
                     formControlName="oximeter_id" notFoundText="Não encontrado" [addTag]="false"
                     (change)="cleanOximeterFormErrorIfNotSelected()">
            <ng-option *ngFor="let option of oximeterList"
                       [disabled]="!option.is_active || option.status == 'OCCUPIED' || option.is_damaged"
                       [value]="option.id">
              {{ option.serial_number }}
            </ng-option>
          </ng-select>

          <app-form-error id="oximeter_id" [formGroup]="formGroup" controlName="oximeter_id">
          </app-form-error>
        </div>

        <div class="col-3  p-0 m-0 px-2">
          <app-select-input id="status" label="Status" [formGroup]="formGroup" [items]="statusList"
                            [hideSelected]="true" [addTag]="false" [multiple]="false" [clearable]="false"
                            [searchable]="false">
          </app-select-input>
        </div>

        <div [formGroup]="formGroup" class="col-6 p-0 m-0 px-2">
          <app-select-input id="professional_id" label="Profissional" [formGroup]="formGroup" [items]="professionalList"
                            [hideSelected]="true" [addTag]="false" [multiple]="false" [clearable]="false"
                            [searchable]="true">
          </app-select-input>
        </div>
      </div>

      <div class="row p-0 m-0 mt-3">
        <div class="col-2 p-0 m-0 px-2">
          <app-basic-input id="sent_at" type="date" [min]="minDate" [formGroup]="formGroup" label="Data de envio"
                           controlName="sent_at" (onValueChange)="validSentAtDate($event)">
          </app-basic-input>
        </div>

        <div class="col-6 col-md-2 p-0 m-0 px-2">
          <app-basic-input id="exam_nights" [formGroup]="formGroup" controlName="exam_nights" label="Noites"
                           min="1" type="number" (onValueChange)="validSentAtDate($event)">
          </app-basic-input>
        </div>

        <div class="col-6 col-md-2 m-0 px-2">
          <app-basic-input id="devolution_date" [min]="minDateDevolution" type="date" [formGroup]="formGroup"
                           label="Data de devolução" controlName="devolution_date"
                           (onValueChange)="validDateDevolution($event)">
          </app-basic-input>
        </div>

        <div class="col-6 col-md-2 p-0 m-0 px-2">
          <label class="field__title">Comprovante de envio</label>
          <div class="row p-0 m-0">
            <div class="d-flex align-items-center col-10 p-0 m-0" [formGroup]="formGroup">
              <label class="btn btn-link-primary">
                <input formControlName='temporary_file' type="file" (change)="addAttachment($event)" class="d-none"
                       multiple="false"
                       *ngIf="!attachmentFeedback">
                <span *ngIf="attachmentFileName == ''">Adicionar arquivo</span>
                <span *ngIf="attachmentFileName != ''">{{ attachmentFileName }}</span>
              </label>
              <span *ngIf="attachmentFeedback">Carregando - {{ attachmentFeedback }}</span>
            </div>
            <div class="col-2 p-0 m-0" *ngIf="downloadFile">
              <i class="i icon icon-download p-0" (click)="downloadFiles(existingExam.file!)"></i>
            </div>
          </div>
          <app-form-error id="temporary_file" [formGroup]="formGroup" controlName="temporary_file">
          </app-form-error>
        </div>
      </div>

      <div class="row p-0 m-0 mt-3">
        <div class="col-12 p-0 px-2">
          <label class="field__title" for="notes">Observações</label>
          <textarea type="text" id="notes" rows="3" formControlName="notes"></textarea>
          <app-form-error id="notes" [formGroup]="formGroup" controlName="notes">
          </app-form-error>
        </div>
      </div>

      <div class="row p-0 m-0 mt-3">
        <div class="col-6 col-md-2 p-0 m-0 px-2 field">
          <label class="field__title" for="notes">Valor</label>
          <input type="text" formControlName="amount_charged" currencyMask
                 [options]="{prefix: '', thousands: '.', decimal: ','}"
                 [ngClass]="formGroup.get('amount_charged')!.errors && formGroup.get('amount_charged')!.touched ? 'error' : ''">
          <app-form-error id="amount_charged" [formGroup]="formGroup" controlName="amount_charged">
          </app-form-error>
        </div>

        <div class="col-6 col-md-2 p-0 m-0 px-2 field">
          <label class="field__title" for="discount">Desconto</label>
          <input type="text" formControlName="discount" currencyMask
                 [options]="{prefix: '', thousands: '.', decimal: ','}"
                 [class.error]="hasErrors('discount')">
          <app-form-error id="discount" [formGroup]="formGroup" controlName="discount">
          </app-form-error>
        </div>

        <div class="col-6 col-md-2 p-0 m-0 px-2">
          <app-basic-input id="due_date" [formGroup]="formGroup" [min]="minDateDue" controlName="due_date"
                           label="Vencimento" type="date" (onValueChange)="validDateDue($event)">
          </app-basic-input>
        </div>

        <div class="col-2 checkbox-option">
          <input id="is_courtesy" type="checkbox" formControlName="is_courtesy">
          <label for="is_courtesy">Cortesia</label>
        </div>

        <div class="col-2 checkbox-option px-0">
          <input id="full_quiz" type="checkbox" formControlName="full_quiz">
          <label for="full_quiz">Questionário completo</label>
        </div>

        <div class="col-2 checkbox-option pr-1">
          <input id="bypass_oximeter_connection" type="checkbox" formControlName="bypass_oximeter_connection"
                 (click)="cleanOximeterFormErrorIfNotSelected()">
          <label for="bypass_oximeter_connection">Sem conexão com o oxímetro</label>
        </div>

      </div>
    </div>
  </div>

  <div class="card" *ngIf="quiz.length > 0">
    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel id="quiz-accordion">
        <ng-template ngbPanelTitle>
          <h2>Questionário
            <i class="icon-Icon-awesome-caret-down" *ngIf="!acc.isExpanded('quiz-accordion')"></i>
            <i class="icon-Icon-awesome-caret-down indicator-reverse" *ngIf="acc.isExpanded('quiz-accordion')"></i>
          </h2>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="quiz-wrapper">
            <div class="question" *ngIf="existingExam?.quiz">
              <h4>Escala de Epworth</h4>
              <p>{{ existingExam?.epworth }}</p>
            </div>

            <ng-container *ngFor="let question of quiz">
              <div class="question" *ngIf="question.variables != 'quiz'">
                <h4>
                  {{ question.question }}
                  <button class="btn btn-link w-auto" (click)="editAnswer(question)">
                    <i class="icon icon-Icon-material-edit"></i>
                  </button>
                  <button class="btn btn-link w-auto" (click)="deleteAnswer(question.question_id)">
                    <i class="icon icon-icon-trash-can"></i>
                  </button>
                </h4>

                <ng-container *ngIf="question.type == 'IMAGE' else noAnswer">
                  <div class="row">
                    <div class="col-6 position-relative p-2" *ngFor="let image of question.answer; let index = index">
                      <img [src]="image" class="img-fluid">

                      <image-cropper [imageURL]="image" [hidden]="true"></image-cropper>

                      <div class="d-flex">
                        <button class="btn btn-info" (click)="cropImage(question, image)">
                          <i class="icon icon-crop"></i>
                          recortar
                        </button>

                        <button class="btn btn-danger" (click)="deleteImage(question.question_id, index)">
                          <i class="icon icon-icon-trash-can"></i>
                          remover
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-template #noAnswer>
                  <p *ngIf="question.answer.length == 0 else answer">-</p>
                </ng-template>

                <ng-template #answer>
                  <p>{{ question.answer }}</p>
                </ng-template>
              </div>
            </ng-container>
          </div>

          <div class="d-flex justify-content-between mt-5">
            <button class="btn btn-danger col-3" (click)="deleteAllAnswers()">Remover todas as respostas</button>
            <div class="d-flex justify-content-between col-6 gap-1">
              <button class="btn btn-outline-primary" (click)="approvedQuiz()">Aprovar questionário</button>
              <button class="btn btn-outline-primary" (click)="answerQuestion()">Responder</button>
              <button class="btn btn-dark" (click)="downloadPdf()" *ngIf="existingExam"
                    [disabled]="downloading">
                <span *ngIf="downloading">Carregando...</span>
                <span *ngIf="!downloading">Baixar PDF</span>
              </button>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-2">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-2" *ngIf="!existingExam?.invoice_url && !isRegister">
      <button (click)="emitCharge()" class="btn btn-outline-primary btn-lg" type="button">
        Emitir cobrança
        <span class="spinner-border" *ngIf="submittingEmitCharge"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-2">
      <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg"
              type="button">
        {{ submitButton }}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>

  <div class="row separator mb-4" *ngIf="!fetchingExams && !isRegister">
    <div class="col-12 card">
      <div class="row p-0 mb-3 d-flex justify-content-between">
        <h3 class="p-0 col-4 m-0">Exames realizados</h3>

        <span *ngIf="isSuperUserOrStaff">
          <button class="btn btn-link" (click)="openImportExamModal()">
            + Importar exame (CSV)
          </button>
        </span>
      </div>

      <div class="row px-0">
        <div class="col-12 p-0 m-0">
          <div class="row table__header">
            <div *ngFor="let header of examsPerformedHeader" [style]="{'min-width': header.widthWithPercentage}"
                 [ngClass]="header.name != 'started_at' && header.name != 'ended_at'  ? 'd-flex justify-content-center' : ''">
              <label>{{ header.label }}</label>
            </div>
          </div>

          <div class="row table__body" *ngFor="let row of examsPerformedData">
            <div *ngFor="let colum of examsPerformedHeader" [style]="{'width': colum.widthWithPercentage}"
                 [ngClass]="colum.name != 'started_at' && colum.name != 'ended_at'  ? 'd-flex justify-content-center' : ''">
              <span *ngIf="colum.name == 'started_at' || colum.name == 'ended_at'">
                {{ getDate(row, colum.name) }}
              </span>

              <span *ngIf="colum.name == 'birthdate'">
                {{ getAge(row) }}
              </span>

              <span *ngIf="colum.name == 'exam_duration'">
                {{ getDuration(row) }}
              </span>

              <span *ngIf="colum.name == 'polysomnography__imc'">
                {{ getKeyByValue(row, colum.name) }}
              </span>

              <span *ngIf="colum.name == 'conditions'" class="d-flex">
                <div *ngFor="let icon of getConditions(row) | keyvalue : originalOrder">
                  <ng-template #tipContent><b>{{ icon.value }}</b></ng-template>
                  <i class="{{icon.key}}" [ngbTooltip]="tipContent" placement="top" container="body"
                     tooltipClass="tooltipClass"></i>
                </div>
              </span>

              <span *ngIf="colum.name == 'spo2'" [style]="getBackground(row, colum.name)">
                {{ getSpo2(row) }}
              </span>

              <span *ngIf="colum.name == 'ido'" [style]="getBackground(row, colum.name)">
                {{ getKeyByValue(row, colum.name) }}
              </span>

              <span *ngIf="colum.name == 'report'">
                <a class="btn btn-link"
                   [ngClass]="isSuperUserOrStaff || canApproveExam || ((row.status == 'UNDER_REVIEW' || row.status == 'DONE') && (existingExam.payment_status == 'APPROVED' || existingExam.is_courtesy)) ? '' : 'is-disabled'"
                   [href]="row.report_file" target="_blank"
                   *ngIf="row.status != 'NO_DATA'">Abrir</a>
                <a class="btn btn-link"
                   *ngIf="row.status == 'NO_DATA'"
                   [href]="row.report_file" target="_blank">Ronco</a>
              </span>

              <span *ngIf="colum.name == 'status'" class="row-height text-center pr-4">
               {{ row.status_display }}
              </span>

              <span *ngIf="colum.name == 'activities'">
                <button class="btn btn-link"
                        *ngIf="isSuperUserOrStaff || (row.status == 'NO_DATA' || row.status == 'REFUSED')"> <!-- se superuser ou (se status == no_data ou status == refused) -->
                  <i class="icon-icon-trash-can" (click)="removeHandler(row)"
                     ngbTooltip="Excluir"></i>
                </button>
              </span>

              <span *ngIf="colum.name == 'activities'">
                <button class="btn btn-link" (click)="openImportClinicUserExamModal(row)"
                        *ngIf="row.status == 'NO_DATA' && canImport"> <!-- status == no_data -->
                  <i class="icon-import-csv-icon"
                     ngbTooltip="Importar CSV"></i>
                </button>
              </span>

              <span *ngIf="colum.name == 'activities'">
                <button class="btn btn-link"
                        *ngIf="row.status == 'UNDER_REVIEW' && (existingExam?.is_courtesy || existingExam?.payment_status == 'APPROVED')"
                        (click)="toggleStatusTable(row)"> <!-- se status == under_review -->
                  <i class="icon-check-icon"
                     ngbTooltip="Liberar"></i>
                </button>
              </span>
            </div>
          </div>

          <div *ngIf="fetchingExams" class="loading">
            <div class="col full-loading text-center">
              <div class="spinner-border spinner-border-xl" role="status">
                <span class="sr-only">Carregando...</span>
              </div>
            </div>
          </div>

          <div *ngIf="error" class="error-message">
            {{ errorMessage }}
          </div>

          <div *ngIf="fetchingExams == false && (!examsPerformedData || examsPerformedData!.length == 0) && !this.error"
               class="message">
            Nenhum exame encontrado
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
