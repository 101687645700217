import { DOCUMENT, KeyValue } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { PolysomnographyExamModel } from 'src/app/core/models/polysomnography-exam.model';
import { PolysomnographyModel } from 'src/app/core/models/polysomnography.model';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import { ProfessionalsService } from 'src/app/core/services/professionals.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { Options } from 'src/app/shared/components/select-input/models/Options';
import * as moment from 'moment';
import { last, map, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { OximeterModel } from 'src/app/core/models/oximeter.model';
import { PatientService } from 'src/app/core/services/patient.service';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { PatientModel } from 'src/app/core/models/patient.model';
import {
  ImportExamModalComponent
} from "../../../../shared/components/modals/import-exam-modal/import-exam-modal.component";
import { ImportClinicUserExamModalComponent }
  from 'src/app/shared/components/modals/import-clinic-user-exam-modal/import-clinic-user-exam-modal.component';
import {Question} from "../../../../core/models/question.model";
import {
  ImageCropperModalComponent
} from "../../../../shared/components/modals/image-cropper-modal/image-cropper-modal.component";
import {
  AnswerQuestionModalComponent
} from "../../../../shared/components/modals/answer-question-modal/answer-question-modal.component";

@Component({
  selector: 'app-exam-form',
  templateUrl: './exam-form.component.html',
  styleUrls: ['./exam-form.component.scss']
})
export class ExamFormComponent implements OnInit {
  addressFormGroup = this.fb.group({
    zipcode: [''],
    address: [''],
    number: [''],
    complement: [''],
    neighborhood: [''],
    city: [''],
    state: ['']
  });

  patientFormGroup = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    phone_number: [''],
    birthdate: [''],
    gender: [''],
    address: this.addressFormGroup,
  });

  formGroup = this.fb.group({
    id: [],
    patient: this.patientFormGroup,
    oximeter_id: [],
    status: [],
    sent_at: [],
    devolution_date: [''],
    period: [''],
    professional_id: [],
    professional__name: [],
    exam_nights: [''],
    patient_id: [''],
    temporary_file: [],
    notes: [],
    due_date: [],
    amount_charged: [],
    discount: ['', [Validators.required]],
    clinic_id: [this.currentClinicId,[Validators.required]],
    is_courtesy: [false],
    bypass_oximeter_connection: [false],
    full_quiz: [false],
  });

  constructor(@Inject(DOCUMENT) private document: Document, private fb: FormBuilder, private oximeterService: OximeterService,
    private router: Router, private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal,
    private sessionManager: SessionManagerService, private toast: ToastrService, private professionalService: ProfessionalsService,
    private patientService: PatientService, private clinicService: ClinicsService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  id!: number;

  isRegister: boolean = true;

  fetchingExams: boolean = false;
  fetchingPatients: boolean = false;
  fetchingProfessionals: boolean = false;
  fetchingClinics: boolean = false;
  fetchingOximeters: boolean = false;

  errorMessage!: string;
  error: boolean = false;

  clearSearchPatient: boolean = false;
  maskSearchPatient: string = '000.000.000-00';

  clearSearchProfessional: boolean = false;
  maskSearchProfessional: string = '000.000.000-00';

  submitting: boolean = false;
  submittingEmitCharge: boolean = false;
  submitButton: String = "Salvar";

  professionalList!: Options[];
  clinicList: Options[] = [];

  patientList!: Options[];
  fetchedPatient: boolean = false;

  oximeterList: OximeterModel[] = [];
  oximeterListAll: OximeterModel[] = [];

  existingExam!: PolysomnographyModel;
  fetchingPolysomnography: boolean = false;

  submittingRemove: boolean = false;

  attachmentFeedback = '';
  attachmentFileName = '';
  downloadFile: boolean = false;

  minDate = '';
  minSentAtDate = '';
  minDateDevolution = '';
  minDateDue = '';
  maxDays = 1;

  dueDate = '';

  patientData?: PatientModel;

  statusList = [
    { value: 'IN_PROGRESS', label: 'Em andamento' },
    { value: 'DONE', label: 'Exames realizados' },
    { value: 'CONCLUDED', label: 'Concluído' },
    { value: 'CANCELED', label: 'Cancelado' }
  ];

  examsPerformedData: PolysomnographyExamModel[] = [];

  examsPerformedHeader = [
    { name: 'started_at', label: 'Início', width: '140px', widthWithPercentage: '12%' },
    { name: 'ended_at', label: 'Fim', width: '140px', widthWithPercentage: '12%' },
    { name: 'exam_duration', label: 'Duração', width: '100px', widthWithPercentage: '7%' },
    { name: 'birthdate', label: 'Idade', width: '80px', widthWithPercentage: '5%' },
    { name: 'polysomnography__imc', label: 'IMC', width: '30px', widthWithPercentage: '5%' },
    { name: 'conditions', label: 'Condições', width: '100px', widthWithPercentage: '14%' },
    { name: 'spo2', label: '↓SpO2', width: '30px', widthWithPercentage: '8%' },
    { name: 'ido', label: 'IDO', width: '30px', widthWithPercentage: '7%' },
    { name: 'report', label: 'Relatório', width: '30px', widthWithPercentage: '8%' },
    { name: 'status', label: 'Status', width: '30px', widthWithPercentage: '12%' },
    { name: 'activities', label: 'Ações', width: '30px', widthWithPercentage: '8%' }
  ];

  quiz: Question[] = [];
  downloading = false;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      this.minDate = moment().format('YYYY-MM-DD');
      if (this.id) {
        this.isRegister = false;
      } else {
        this.formGroup.disable();
        this.formGroup.get('clinic_id')?.enable();
        this.patientFormGroup.disable();
        this.formGroup.get('devolution_date')?.disable();
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    this.fetchQuiz();
    this.fetchOximeters();
    this.fetchExamsList();
    this.fetchProfessionals();
    this.fetchClinicList();
    if (this.id) setTimeout(() => this.fetchExamExistingData(), 1000);
  }

  get fetchingData(): boolean {
    return this.fetchingClinics || this.fetchingPatients || this.fetchingProfessionals || this.fetchingOximeters;
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_polysomnography);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_polysomnography);
    }
  }

  get canImport() {
    return this.sessionManager.getUserPermission(PermissionConst.import_csv_polysomnography);
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_polysomnography);
  }

  get hasRemainingNights() {
    return this.existingExam!.remaining_nights! > 0
  }

  get currentClinic() {
    if (!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent();
    }
    return '';
  }

  get currentClinicId() {
    if (!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  cancelHandler() {
    this.router.navigate(['dashboard/exams']);
  }


  setPatientForm(ev: any) {
    let patient = this.patientList.find(item => item.value == ev)?.items;
    this.patientFormGroup.patchValue(patient);
    let phone = patient?.phone_numbers?.map((phone: any) => {
      return `${phone.country_code}${phone.phone_number}`
    });
    if (phone) {
      this.patientFormGroup.get('phone_number')?.setValue(phone![0]);
    }
    this.patientFormGroup.get('name')?.setValue(ev);
    this.addressFormGroup.disable();
    this.minSentAtDate = moment().format('YYYY-MM-DD');
  }

  fetchPatientList(clinicId: any) {
    this.fetchingPatients = true;
    this.patientService.clinicUserPatientGetAll(clinicId).subscribe(response => {
      this.fetchingPatients = false;
      this.patientList = response.map(item => {
        let cpf = item.document_number ?? '-';
        return {
          value: item.id?.toString(),
          label: `${item.name} - CPF ${cpf}`,
          items: item
        }
      });
    }, error => {
      this.fetchingPatients = false;
    });
  }

  setClinic(ev: any) {
    if (ev) {
      this.fetchPatientList(ev);
      this.formGroup.enable();
      this.patientFormGroup.enable();
      let clinic = this.clinicList.find(item => item.value == ev)?.items;
      this.formGroup.get('amount_charged')?.addValidators(Validators.required);
      this.formGroup.get('amount_charged')?.addValidators(Validators.min(clinic.minimum_amount_charged_by_clinic));
      this.formGroup.get('amount_charged')?.setValue(clinic.amount_charged_by_clinic ?? clinic.minimum_amount_charged_by_clinic);
      this.maxDays = clinic.maximum_nights_per_exam ?? '';
      this.formGroup.get('exam_nights')?.addValidators([Validators.max(this.maxDays), Validators.min(1)]);
      this.formGroup.get('exam_nights')?.setValue(clinic.maximum_nights_per_exam ?? 7);
      this.formGroup.get('discount')?.setValue(clinic.discount ?? 0);
      if (clinic.courtesy_exams > 0) {
        this.formGroup.get('is_courtesy')?.enable();
      } else {
        this.formGroup.get('is_courtesy')?.disable();
      }
      if(clinic.exams_due_date) {
        this.minDateDue = moment().format('YYYY-MM-DD');
        this.dueDate = moment(clinic.exams_due_date).format('YYYY-MM-DD');
      } else {
        this.minDateDue = moment().format('YYYY-MM-DD');
      }
      this.oximeterList = this.oximeterListAll.filter(item => item.clinic_id == ev);
      this.formGroup.get('due_date')?.setValue(this.dueDate);
    }
  }

  clearPatient() {
    this.maskSearchPatient = '000.000.000-00';
    this.patientFormGroup.reset();
    this.patientFormGroup.get('name')?.enable();
    this.clearSearchPatient = false;
  }

  clearProfessional() {
    this.maskSearchProfessional = '000.000.000-00';
    this.formGroup.get('professional_id')?.reset();
    this.formGroup.get('professional__name')?.enable();
    this.formGroup.get('professional__name')?.reset();
    this.clearSearchProfessional = false;
  }

  viewExams() {
    this.router.navigate([`dashboard/patients/edit/${this.existingExam.patient!.id}/polysomnography`]);
  }

  fetchQuiz() {
    this.oximeterService.listQuiz(this.id, this.currentClinicId).subscribe({
      next: response => {
        this.quiz = response;
      }, error: error => {
        console.error(error);
      }
    });
  }

  fetchExamsList() {
    const fnResponse = (response: any) => {
      this.examsPerformedData = response;
      this.fetchingExams = false;
    };

    const fnError = (error: HttpErrorResponse) => {
      this.fetchingExams = false;
      this.error = true;
      this.errorMessage = error.error['detail'];
    }
    if(!this.isRegister){
      if (this.isSuperUserOrStaff) {
        this.oximeterService.polysomnographyExamsList(this.id).subscribe(fnResponse, fnError);
      } else {
        this.oximeterService.clinicUserPolysomnographyExamsList(this.currentClinicId, this.id).subscribe(fnResponse, fnError);
      }
    }
  }

  fetchOximeters() {
    this.fetchingOximeters = true;
    this.oximeterService.oximetersAll(this.currentClinicId).subscribe(response => {
      this.fetchingOximeters = false;
      this.oximeterList = response;
      this.oximeterListAll = response;
    }, error => {
      this.fetchingOximeters = false;
    });
  }

  fetchProfessionals() {
    this.fetchingProfessionals = true;
    this.professionalService.clinicUserGetAll(this.currentClinicId).subscribe(response => {
      this.fetchingProfessionals = false;
      this.professionalList = response.map(item => {
        return {
          value: item.id?.toString(),
          label: `${item.name} - CRO ${item.cro}`
        } as Options;
      });
    }, error => {
      this.fetchingPatients=false
    });
  }

  fetchClinicList() {
    if (this.isSuperUserOrStaff) {
      this.fetchingClinics = true;
      this.clinicService.getAll().subscribe(response => {
        this.fetchingClinics = false;
        this.clinicList = response.map(item => {
          return {
            value: item.id!.toString(),
            label: item.name!,
            items: item
          }
        });
      }, error => {
        this.fetchingClinics = false;
      });
    } else {
      this.clinicList.push(this.sessionManager.getClinicCurrent());
      this.setClinic(this.currentClinicId);
    }
  }

  polysomnography?: PolysomnographyModel;
  fetchExamExistingData() {
    const fnResponse = (response: PolysomnographyModel) => {
      this.fetchPatientList(response.clinic_id);
      this.maskSearchPatient = '';
      this.maskSearchProfessional = '';
      this.clearSearchProfessional = true;
      this.existingExam = response;
      this.formGroup.patchValue(response);
      this.fetchedPatient = true;
      this.treatMinSentAtAndMinDevolutionDate();
      let hasOximeterList = this.oximeterListAll.find(item => item.id == this.existingExam.oximeter_id);
      if(!hasOximeterList && this.existingExam.oximeter) {
        this.oximeterList.push(this.existingExam.oximeter);
      }
      this.formGroup.get('oximeter_id')?.disable();
      this.formGroup.get('bypass_oximeter_connection')?.disable();
      this.formGroup.get('full_quiz')?.disable();
      if (this.existingExam.status == 'CONCLUDED' || this.existingExam.status == 'CANCELED') {
        this.formGroup.get('status')?.disable();
        this.formGroup.get('professional_id')?.disable();
        this.formGroup.get('exam_nights')?.disable();
        this.formGroup.get('devolution_date')?.disable();
        this.formGroup.get('notes')?.disable();
        this.formGroup.get('temporary_file')?.disable();
        this.formGroup.get('discount')?.disable();
        this.formGroup.get('amount_charged')?.disable();
        this.formGroup.get('is_courtesy')?.disable();
        this.formGroup.get('due_date')?.disable();
        this.formGroup.get('sent_at')?.disable();
      } else if (this.existingExam.payment_status == 'APPROVED') {
        this.formGroup.get('discount')?.disable();
        this.formGroup.get('amount_charged')?.disable();
        this.formGroup.get('due_date')?.disable();
        this.formGroup.get('is_courtesy')?.disable();
      }
      // this.formGroup.get('exam_nights')?.addValidators([Validators.min(1), Validators.max(this.currentClinic.maximum_nights_per_exam)]);
      this.formGroup.get('professional_id')?.setValue(this.existingExam.professional_id);
      this.patientFormGroup.get('name')?.setValue((this.existingExam.patient?.id)?.toString());

      if (this.existingExam.filename) {
        let filename: string = this.existingExam.filename;
        this.attachmentFileName = filename.split('/').pop() ?? '';
        this.downloadFile = true;
      }
      this.fetchingPolysomnography = true;
      this.patientFormGroup.disable();
      if (response.clinic_id) {
        this.formGroup.get('clinic_id')?.disable();
      }
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.cancelHandler();
    }
    if (this.isSuperUserOrStaff) {
      this.oximeterService.polysomnographyGet(this.id).subscribe(fnResponse, fnError);
    } else {
      this.oximeterService.clinicUserPolysomnographyGet(this.currentClinicId, this.id).subscribe(fnResponse, fnError);
    }
  }

  treatMinSentAtAndMinDevolutionDate() {
    let treatedSentAtAndDevolutionDate = this.treatSentAtAndDevolutionDate(this.existingExam.sent_at, this.existingExam.devolution_date);
    let sent_at = treatedSentAtAndDevolutionDate[0];
    if (sent_at) {
      let date_time = moment(this.existingExam.devolution_date).format('YYYY-MM-DD');
      let days = this.existingExam.exam_nights;
      let date_now = moment().format('YYYY-MM-DD') > sent_at;
      if (date_now) {
        this.minSentAtDate = sent_at;
        this.formGroup.get('sent_at')?.disable();
        this.formGroup.get('period')?.disable();
      } else {
        this.minSentAtDate = moment().format('YYYY-MM-DD');
      }
      this.maxDays = moment(date_time).diff(moment(sent_at), 'days');
      this.minDateDevolution = moment(sent_at).add(days, 'day').format('YYYY-MM-DD');
      this.formGroup.get('devolution_date')?.setValue(date_time);
      this.formGroup.get('sent_at')?.setValue(sent_at);
    }
  }

  setDateDevolution() {
    let sent_at = moment(this.formGroup.get('sent_at')?.value);
    let examNights = this.formGroup.get('exam_nights')?.value;
    this.minDateDevolution = moment(sent_at).add(examNights, 'day').format('YYYY-MM-DD');
    this.formGroup.get('devolution_date')?.setValue(this.minDateDevolution);
    this.formGroup.get('devolution_date')?.enable();
  }

  validDateDevolution(ev: any) {
    let devolution_date = moment(this.formGroup.get('devolution_date')?.value);
    if (moment(this.minDateDevolution) > devolution_date) {
      this.formGroup.get('devolution_date')?.setErrors({ response: 'Data inválida' });
    }
  }

  validSentAtDate(ev: any) {
    if (this.formGroup.get('sent_at')?.value) {
      let sent_at = moment(this.formGroup.get('sent_at')?.value);
      if (moment(this.minSentAtDate) > sent_at) {
        this.formGroup.get('sent_at')?.setErrors({ response: 'Data inválida' });
      } else {
        this.setDateDevolution();
      }
    } else {
      this.formGroup.get('devolution_date')?.setValue(null);
    }
  }


  validDateDue(ev: any) {
    let due_date = moment(this.formGroup.get('due_date')?.value);
    if(moment(this.minDateDue) > due_date) {
      this.formGroup.get('due_date')?.setErrors({ response: 'Data inválida' });
    }
  }

  cleanOximeterFormErrorIfNotSelected() {
    let oximeterId = this.formGroup.get('oximeter_id')
    if(!oximeterId?.value) {
      this.formGroup.get('oximeter_id')?.updateValueAndValidity();
      this.formGroup.get('devolution_date')?.updateValueAndValidity();
      this.formGroup.get('sent_at')?.updateValueAndValidity();
    }
  }

  downloadFiles(url: string) {
    window.open(url, '_blank');
  }

  addAttachment(event: any) {
    let files: FileList = event.target.files;
    this.attachmentFileName = files[0].name;
    this.downloadFile = false;
    let formData: FormData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
    });
    this.oximeterService.uploadTemporaryFile(formData).pipe(
      map((event: any, formData: any) => {
        switch (event['type']) {
          case HttpEventType.UploadProgress:
            let percentage = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            return `Envio de arquivo em ${percentage}%.`;
          case HttpEventType.Response:
            event.body.forEach((attachment: any) => {
              this.formGroup.get('temporary_file')?.setValue(attachment.id);
            });
            return '';
          default:
            return null;
        }
      }),
      tap(message => {
        this.attachmentFeedback = message || '';
      }),
      last()
    ).subscribe(response => {
      /**/
    }, error => {
      console.error(error);
    });
  }

  getKeyByValue(object: any, name: string) {
    return object[name];
  }

  getDate(object: any, name: string) {
    if (!object[name])
      return '-';
    return moment(object[name]).format('DD/MM/YYYY HH:mm');
  }

  getAge(object: any) {
    let startedAt = moment(object['started_at']);
    if (!this.existingExam)
      return '';
    return startedAt.diff(moment(this.existingExam.patient!.birthdate), 'years');
  }

  getDuration(object: any) {
    let hours = Math.floor(object.exam_duration / 60);
    let minutes = object.exam_duration % 60;
    if (minutes.toString().length == 1) return `${hours}h0${minutes}min`;
    else return `${hours}h${minutes}min`;
  }

  getSpo2(object: any) {
    return `${object.spo2_percentage_below_90}%`;
    let minutes = object.spo2 ? object.spo2 + "min" : '-';
    let percentage = object.percentage_spo2 ?? '-';
    return `${minutes} (${percentage}%)`;
  }

  getGender(object: any) {
    if (object.patient.gender == 'female' || object.patient.gender == 'FEMALE') {
      return 'icon-female';
    } else if (object.patient.gender == 'male' || object.patient.gender == 'MALE') {
      return 'icon-male'
    } else return '';
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  getConditions(exam: any) {
    let conditions = new Map<string, string>();

    if (exam.conditions) {
      exam.conditions.forEach((item: string) => {
        if (item == 'ALCOHOLIC_BEVERAGE') {
          conditions.set('icon-Icon-awesome-cocktail', 'Bebida alcoólica');
        } else if (item == 'STUFFY_NOSE') {
          conditions.set('icon-Grupo-409', 'Nariz entupido');
        } else if (item == 'SEDATIVES') {
          conditions.set('icon-pill', 'Sedativos');
        }
      });
    }
    if (exam.snoring_treatment == 'CPAP') {
      conditions.set('icon-cpap', 'CPAP');
    } else if (exam.snoring_treatment == 'INTRAORAL_SNORING_DEVICE') {
      conditions.set('icon-intraoral_snoring_device', 'Aparelho de Ronco Intraoral');
    }

    return conditions;
  }


  getBackground(object: any, name: string) {
    let style = {
      'background-color': '#000',
      'border-radius': '4px',
      'padding': '0 4px',
      'color': "#ffffff"
    }
    if (name == 'spo2') {
      if (object.spo2_percentage_below_90 == 0) {
        style['background-color'] = '#6aa84f';
      } else if (object.spo2_percentage_below_90 > 0 && object.spo2_percentage_below_90 < 10) {
        style['background-color'] = '#ff9900';
      } else {
        style['background-color'] = '#990000';
      }
    } else {
      if (object[name] >= 0 && object[name] <= 4.9) {
        style['background-color'] = '#88f988';
      } else if (object[name] >= 5 && object[name] <= 14.9) {
        style['background-color'] = '#f5f988';
      } else if (object[name] >= 15 && object[name] <= 29.9) {
        style['background-color'] = '#ffd0a8';
      } else {
        style['background-color'] = '#f6a9a8';
      }
    }
    return style;
  }

  openImportExamModal() {
    const modalRef = this.modalService.open(ImportExamModalComponent, {centered: true});
    modalRef.componentInstance.polysomnographyId = this.id;
    modalRef.result.then(success => {
      if (success) {
        this.toast.success('Exame importado');
        this.fetchExamsList();
      }
    });
  }

  openImportClinicUserExamModal(exam: PolysomnographyExamModel) {
    const modalRef = this.modalService.open(ImportClinicUserExamModalComponent, { centered: true });
    modalRef.componentInstance.polysomnographyId = this.id;
    modalRef.componentInstance.examId = exam.id;
    modalRef.result.then(success => {
      if (success) {
        this.toast.success('Exame importado com sucesso');
        this.fetchExamsList();
      }
    });
  }

  toggleStatusTable(exam: PolysomnographyExamModel) {
    let polysomnography_id = this.existingExam.id!;
    let exam_id = exam.id!;
    if (exam.status == 'UNDER_REVIEW') {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "Deseja confirmar este exame?";
      modalRef.result.then(response => {
        if (response == true) {
          this.oximeterService.statusExamDone(polysomnography_id, exam_id, this.currentClinicId).subscribe(response => {
            this.toast.success('Exame concluído com sucesso');
            this.fetchExamsList();
            this.examsPerformedData = this.examsPerformedData.map(item => {
              if (item.id == exam_id) {
                item.status = "DONE";
              }
              return item;
            });
          }, (error) => {
            this.mapErrorResponse(error);
          });
        }
      });
    }
  }

  emitCharge() {
    this.submittingEmitCharge = true;
    this.oximeterService.polysomnographyResendInvoice(this.id, this.currentClinicId).subscribe((response: any) => {
      this.toast.success("Fatura gerada com sucesso", "Sucesso");
      this.existingExam.invoice_url = response.invoice_url;
      this.submittingEmitCharge = false;
    }, (error: HttpErrorResponse) => {
      this.toast.error(error.error['detail'], "Erro");
      this.submittingEmitCharge = false;
    });
  }

  treatSentAtAndDevolutionDate(sentAt: string | undefined | null, devolutionDate: string | undefined | null) {
    let treatedSentAt = null;
    let treatedDevolutionDate = null;
    if (sentAt) {
      treatedSentAt = moment(sentAt).format('YYYY-MM-DD');
    }
    if (devolutionDate) {
      treatedDevolutionDate = moment(devolutionDate).format('YYYY-MM-DD');
    }
    return [treatedSentAt, treatedDevolutionDate]
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    this.patientFormGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.submitting = true;
      let examData = this.formGroup.getRawValue() as PolysomnographyModel
      examData.patient_id = this.patientFormGroup.get('name')?.value;
      let patient = this.patientList.find(item => item.value == examData.patient_id)?.items;
      examData.patient!.name = patient.name;
      let treatedSentAtAndDevolutionDate = this.treatSentAtAndDevolutionDate(examData.sent_at, examData.devolution_date);
      examData.sent_at = treatedSentAtAndDevolutionDate[0];
      examData.devolution_date = treatedSentAtAndDevolutionDate[1];
      if (this.isRegister) {
        this.examRegister(examData);
      } else {
        this.examEdit(examData);
      }
    } else {
      this.toast.error("Preencha corretamente os campos obrigatórios", "Erro");
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }




  examRegister(examData: PolysomnographyModel) {
    const fnResponse = (response: any) => {
      this.toast.success("Exame criado com sucesso", "Sucesso");
      this.cancelHandler();
      this.submitting = false;
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    };

    examData.notes = examData.notes ?? '';
    if (!examData.temporary_file) {
      examData.temporary_file = undefined;
    }

    if (this.isSuperUserOrStaff) {
      this.oximeterService.polysomnographyRegister(examData).subscribe(fnResponse, fnError);
    } else {
      this.oximeterService.clinicUserPolysomnographyRegister(this.currentClinicId, examData).subscribe(fnResponse, fnError);
    }
  }

  examEdit(examData: PolysomnographyModel) {
    const fnResponse = (response: any) => {
      this.toast.success("Exame alterado com sucesso", "Sucesso");
      this.cancelHandler();
      this.submitting = false;
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    }
    if (this.existingExam.file || !examData.temporary_file) {
      examData.temporary_file = undefined;
    }
    examData.notes = examData.notes ?? '';
    examData.oximeter_id = examData.oximeter_id == this.existingExam.oximeter_id ? undefined : examData.oximeter_id;
    if (this.isSuperUserOrStaff) {
      this.oximeterService.polysomnographyEdit(examData).subscribe(fnResponse, fnError);
    } else {
      this.oximeterService.clinicUserPolysomnographyEdit(this.currentClinicId, examData).subscribe(fnResponse, fnError);
    }
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro",);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.toast.error('Preencha corretamente os campos obrigatórios', 'Erro');
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      'id', 'patient_id', 'oximeter_id',
      'status', 'sent_at', 'devolution_date', 'period',
      'exam_nights', 'notes', 'temporary_file', 'professional_id',
      'discount', 'amount_charged', 'due_date'
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });

    if (errorResponse.error.patient_id) {
      this.patientFormGroup.get('name')?.setErrors({ response: 'Nenhum paciente foi selecionado' });
    }

    if (errorResponse.error.temporary_file) {
      this.formGroup.get('temporary_file')?.setErrors({ response: 'Você precisa adicionar um arquivo' });
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    }
    else return true;
  }

  hasErrors(formControlName: string): boolean {
    return (this.formGroup.get(formControlName)!.errors && this.formGroup.get(formControlName)!.touched) || false;
  }

  get canApproveExam() {
    return this.sessionManager.accountData.can_approve_exam == true
  }

  removeHandler(exam: PolysomnographyExamModel) {
    let polysomnography_id = this.existingExam.id!;
    let exam_id = exam.id!;
    this.submittingRemove = true;
    let message = 'Este exame será excluído caso prossiga.';
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = message;
    modalRef.result.then((result) => {
      if (result == true) {
        this.oximeterService.examDelete(polysomnography_id, exam_id as PolysomnographyExamModel, this.currentClinicId).subscribe(() => {
          this.toast.success('Exame excluido com sucesso', "Sucesso");
          this.fetchExamsList();
        }, (errorResponse: HttpErrorResponse) => {
          this.submittingRemove = false;
          this.mapErrorResponse(errorResponse);
        });
      } else {
        this.submittingRemove = false;
      }
    });
  }

  editAnswer(question: Question) {
    let answerQuestionModal = this.modalService.open(AnswerQuestionModalComponent);
    answerQuestionModal.componentInstance.polysomnographyId = this.id;
    answerQuestionModal.componentInstance.questionId = question.question_id;
    answerQuestionModal.componentInstance.clinicId = this.currentClinicId;

    answerQuestionModal.result.then(_question => {
      if (_question) {
        question.answer = _question.answer;
      }
    })

  }

  answerQuestion() {
    let answerQuestionModal = this.modalService.open(AnswerQuestionModalComponent);
    answerQuestionModal.componentInstance.polysomnographyId = this.id;
    answerQuestionModal.componentInstance.clinicId = this.currentClinicId;

    answerQuestionModal.result.then(_question => {
      if (_question) {
        this.quiz.push(_question);
      }
    })
  }

  approvedQuiz() {
    let clinicId = this.currentClinicId;
    let modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = 'Confirma a Aprovação do Questionários?';
    modalRef.result.then(confirmed => {
      if (confirmed) {
        this.oximeterService.sendApprovedQuizFlow(this.id, clinicId).subscribe(response => {
          this.toast.success('Fluxo enviado com sucesso', 'Sucesso');
        }, error => {
          this.mapErrorResponse(error);
        })
      }
    })
  }

  deleteImage(questionId: number, index: number) {
    let clinicId = this.currentClinicId;
    let confirmed = confirm('Tem certeza que deseja excluir esta resposta?');
    if (confirmed) {
      this.oximeterService.deleteImage(this.id, questionId, index, clinicId).subscribe({
        next: response => {
          this.toast.success('Imagem removida', 'Sucesso');
          let question = this.quiz.find((e) => e.question_id == questionId);
          question?.answer?.splice(index, 1);
        }
      });
    }
  }

  deleteAnswer(questionId: number) {
    let clinicId = this.currentClinicId;
    let confirmed = confirm('Tem certeza que deseja excluir esta resposta?');
    if (confirmed) {
      this.oximeterService.deleteAnswer(this.id, questionId, clinicId).subscribe({
        next: response => {
          this.toast.success('Resposta removida', 'Sucesso');
          this.quiz = this.quiz.filter((e) => e.question_id != questionId);
        }
      });
    }
  }

  deleteAllAnswers() {
    let clinicId = this.currentClinicId;
    let confirmed = confirm('Tem certeza que deseja excluir todas as respostas?');
    if (confirmed) {
      this.oximeterService.deleteAllAnswers(this.id, clinicId).subscribe({
        next: response => {
          this.toast.success('Todas as respostas foram removidas', 'Sucesso');
          this.quiz = [];
        }
      });
    }
  }

  downloadPdf() {
    this.downloading = true
    this.oximeterService.generateQuizPdf(this.existingExam.id!).subscribe({
      next: response => {
        window.open(response.url, '_blank');
        this.downloading = false;
      }, error: error => {
        this.toast.error('Não foi possível gerar o PDF', 'Erro');
        this.downloading = false;
      }
    });
  }

  cropImage(question: Question, image: string) {
    let cropperModal = this.modalService.open(ImageCropperModalComponent);
    cropperModal.componentInstance.question = question;
    cropperModal.componentInstance.imageUrl = image;
    cropperModal.componentInstance.isSelfie = question.question.includes('Selfie');
    cropperModal.componentInstance.polysomnographyId = this.id;

    cropperModal.result.then(answer => {
      if (answer) {
        question.answer = answer;
      }
    });
  }
}
