import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {PermissionConst} from "../../../../../core/models/permission-const.model";
import {SessionManagerService} from "../../../../../core/services/session-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CouponModel} from "../../../../../core/models/coupon.model";
import {RegistersService} from "../../../../../core/services/registers.service";
import {ToastrService} from "ngx-toastr";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-coupon-form',
  templateUrl: './coupon-form.component.html',
  styleUrls: ['./coupon-form.component.scss']
})
export class CouponFormComponent implements OnInit {

  isRegister: boolean = true;
  loading: boolean = true;
  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";
  button: boolean = false;
  isActive: boolean = true;

  id!:number
  existingCouponData!: CouponModel;

  formGroup = this.fb.group({
    name: ['', [Validators.required]],
    exams_nights: ['', [Validators.required, Validators.min(1)]],
    full_quiz: [true, [Validators.required]],
    description: ['']
  });

  constructor(private fb: FormBuilder, private sessionManager: SessionManagerService, private toast: ToastrService,
              private registersService: RegistersService, private activatedRoute: ActivatedRoute,
              private router: Router) { }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_coupon);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.loading = true;
        this.isRegister = false;
        this.initializeForm();
      } else {
        this.loading = false;
      }
    });
  }

  treatButtonsAndForm() {
    if (this.existingCouponData.is_active) {
      this.removeButton = 'Desativar';
      this.submitButton = 'Salvar';
      this.button = true;
    } else {
      this.button = false;
      this.submitButton = 'Reativar';
      this.formGroup.disable();
    }
  }

  initializeForm() {
    this.registersService.couponGet(this.id).subscribe(response => {
      this.existingCouponData = response;
      this.formGroup.patchValue(this.existingCouponData);
      this.treatButtonsAndForm();
      if (!this.existingCouponData.is_active) {
        this.isActive = false;
      }
      this.loading = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error)
    })
  }

  couponRegister(couponData: CouponModel) {
    this.registersService.couponsRegister(couponData).subscribe(response => {
      this.toast.success('Cupon criado com sucesso', 'Sucesso')
      this.cancelHandler();
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.submitting = false;
      this.mapErrorResponse(response);
    })
  }

  couponEdit(couponData: CouponModel) {
    couponData.is_active = true;
    this.registersService.couponEdit(this.id, couponData).subscribe(response => {
      this.toast.success("Cupon alterado com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      if (!this.isActive) {
        this.formGroup.disable()
      }
      this.mapErrorResponse(error);
      this.submitting = false;
    })
  }

  submitHandler() {
    this.formGroup.markAllAsTouched()
    let couponData = this.formGroup.getRawValue() as CouponModel;
    if (!this.isActive) {
      this.formGroup.enable()
    }
    if (this.formGroup.valid) {
      this.submitting = true;
      if (this.isRegister) {
        this.couponRegister(couponData)
      } else {
      this.couponEdit(couponData)
      }
    }
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/coupons'])
  }

  removeHandler() {
    this.submitting = true;
    this.submittingRemove = true;
    this.registersService.couponRemove(this.id).subscribe(response => {
      this.toast.success('Coupon desativado com sucesso', 'Sucesso');
      this.cancelHandler();
    }, (erro: HttpErrorResponse) => {
      this.mapErrorResponse(erro);
    })
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "id", "name", "full_quiz", "description", "exams_nights"
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name]) {
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
      }
    });
  }

}
