import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ListModel} from '../models/list.model';
import {OximeterModel} from '../models/oximeter.model';
import {PolysomnographyExamModel} from '../models/polysomnography-exam.model';
import {PolysomnographyModel} from '../models/polysomnography.model';
import {Question} from "../models/question.model";

@Injectable({
  providedIn: 'root'
})
export class OximeterService {
  constructor(private httpClient: HttpClient) {
  }

  oximetersList(clinicId: string = "", searchString: string = "", orderingParam: string = "", status: string = '', isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/oximeters/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingParam}&status=${status}&is_active=${isActive}&page=${page}`);
  }

  oximetersAll(clinicId: string = ""): Observable<OximeterModel[]> {
    return this.httpClient.get<OximeterModel[]>(`${environment.API_URL}/oximeters/all/?clinic_id=${clinicId}`);
  }

  oximeterGet(clinicId: string = "", oximeterId: number): Observable<OximeterModel> {
    return this.httpClient.get<OximeterModel>(`${environment.API_URL}/oximeters/${oximeterId}/?clinic_id=${clinicId}`);
  }

  oximeterRegister(clinicId: string = "", oximeterData: OximeterModel): Observable<OximeterModel> {
    return this.httpClient.post<OximeterModel>(`${environment.API_URL}/oximeters/?clinic_id=${clinicId}`, JSON.stringify(oximeterData));
  }

  oximeterEdit(clinicId: string = "", oximeterData: OximeterModel): Observable<OximeterModel> {
    return this.httpClient.patch<OximeterModel>(`${environment.API_URL}/oximeters/${oximeterData.id}/?clinic_id=${clinicId}`, JSON.stringify(oximeterData));
  }

  polysomnographyList(searchString: string = "", orderingParam: string = "", clinicId: string = "", status: string = '', isActive: boolean = true, dateSince: string = '', dateUntil: string = '', page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/polysomnography/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingParam}&status=${status}&is_active=${isActive}&since=${dateSince}&until=${dateUntil}&page=${page}`);
  }

  polysomnographyGet(polysomnographyId: number): Observable<PolysomnographyModel> {
    return this.httpClient.get<PolysomnographyModel>(`${environment.API_URL}/polysomnography/${polysomnographyId}/`);
  }

  polysomnographyRegister(polysomnographyData: PolysomnographyModel): Observable<PolysomnographyModel> {
    return this.httpClient.post<PolysomnographyModel>(`${environment.API_URL}/polysomnography/`, JSON.stringify(polysomnographyData));
  }

  polysomnographyEdit(polysomnographyData: PolysomnographyModel): Observable<PolysomnographyModel> {
    return this.httpClient.patch<PolysomnographyModel>(`${environment.API_URL}/polysomnography/${polysomnographyData.id}/`, JSON.stringify(polysomnographyData));
  }

  listQuiz(polysomnographyId: number, clinicId: string = ''): Observable<Question[]> {
    return this.httpClient.get<Question[]>(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/?clinic_id=${clinicId}`);
  }

  listQustionsWithoutAnswer(polysomnographyId: number, clinicId: number): Observable<Question[]> {
    return this.httpClient.get<Question[]>(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/questions_without_answer/?clinic_id=${clinicId}`)
  }

  getQuestion(polysomnographyId: number, questionId: number, clinicId: number): Observable<Question> {
    return this.httpClient.get<Question>(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/question/${questionId}/?clinic_id=${clinicId}`)
  }

  answerQuestion(polysomnographyId: number, quizQuestionId: number, clinicId: number, data: any): Observable<Question> {
    let hasImage = false;
    if (data instanceof FormData) {
      hasImage = true;
    }
    return this.httpClient.patch<Question>(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/${quizQuestionId}/answer/?upload=${hasImage}&clinic_id=${clinicId}`, data)
  }

  deleteImage(polysomnographyId: number, questionId: number, imageIndex: number, clinicId: string) {
    return this.httpClient.patch(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/${questionId}/remove_image/?clinic_id=${clinicId}`, {file_index: imageIndex});
  }

  deleteAnswer(polysomnographyId: number, questionId: number, clinicId: string) {
    return this.httpClient.delete(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/${questionId}/?clinic_id=${clinicId}`);
  }

  deleteAllAnswers(polysomnographyId: number, clinicId: string) {
    return this.httpClient.delete(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/remove_all/?clinic_id=${clinicId}`);
  }

  sendApprovedQuizFlow(polysomnographyId: number, clinicId: string) {
    return this.httpClient.patch(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/approve_quiz/?clinic_id=${clinicId}`, null);
  }

  patientExamsList(patientId: number): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/${patientId}/polysomnography_exams/`);
  }

  polysomnographyExamsList(polysomnographyId: number, is_active = true): Observable<PolysomnographyExamModel[]> {
    return this.httpClient.get<PolysomnographyExamModel[]>(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/all/?is_active=${is_active}`);
  }

  polysomnographyResendInvoice(polysomnographyId: number, clinicId: string = '') {
    return this.httpClient.patch(`${environment.API_URL}/polysomnography/${polysomnographyId}/resend_invoice/?clinic_id=${clinicId}`, {});
  }

  examRegister(polysomnographyId: number, examData: PolysomnographyExamModel): Observable<PolysomnographyExamModel> {
    return this.httpClient.post<PolysomnographyExamModel>(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/`, JSON.stringify(examData));
  }

  examEdit(polysomnographyId: number, examData: PolysomnographyExamModel): Observable<PolysomnographyExamModel> {
    return this.httpClient.patch<PolysomnographyExamModel>(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/${examData.id}/`, JSON.stringify(examData));
  }

  examDelete(polysomnographyId: number, examId: PolysomnographyExamModel, clinicId: number): Observable<any> {
    return this.httpClient.delete(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/${examId}/?clinic_id=${clinicId}`);
  }

  //APP CONFIMAR EXAME

  statusExamDone(polusomnographyId: number, examId: number, clinicId: number) {
    return this.httpClient.patch<any>(`${environment.API_URL}/polysomnography/${polusomnographyId}/exams/${examId}/status/?clinic_id=${clinicId}`, {"status": "DONE"});
  }

  uploadTemporaryFile(formData: FormData) {
    return this.httpClient.post(`${environment.API_URL}/temporary_files/`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  // CLINIC USER

  clinicUserPolysomnographyGet(clinicId: number, polysomnographyId: number): Observable<PolysomnographyModel> {
    return this.httpClient.get<PolysomnographyModel>(`${environment.API_URL}/polysomnography/${polysomnographyId}/?clinic_id=${clinicId}`);
  }

  clinicUserPolysomnographyRegister(clinicId: number, polysomnographyData: PolysomnographyModel): Observable<PolysomnographyModel> {
    return this.httpClient.post<PolysomnographyModel>(`${environment.API_URL}/polysomnography/?clinic_id=${clinicId}`, JSON.stringify(polysomnographyData));
  }

  clinicUserPolysomnographyEdit(clinicId: number, polysomnographyData: PolysomnographyModel): Observable<PolysomnographyModel> {
    return this.httpClient.patch<PolysomnographyModel>(`${environment.API_URL}/polysomnography/${polysomnographyData.id}/?clinic_id=${clinicId}`, JSON.stringify(polysomnographyData));
  }

  clinicUserPolysomnographyExamsList(clinicId: number, polysomnographyId: number, is_active = true): Observable<PolysomnographyExamModel[]> {
    return this.httpClient.get<PolysomnographyExamModel[]>(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/all/?clinic_id=${clinicId}&is_active=${is_active}`);
  }

  clinicUserPatientExamsList(clinicId: number, patientId: number): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/${patientId}/polysomnography_exams/?clinic_id=${clinicId}`);
  }

  generateQuizPdf(polysomnographyId: number): Observable<{url: string}> {
    return this.httpClient.patch<{url: string}>(`${environment.API_URL}/polysomnography/${polysomnographyId}/generate_quiz_pdf/`, {});
  }
}
