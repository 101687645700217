import { Component, Input, EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Options } from './models/Options';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit {

  @Input() id!: string;
  @Input() formGroup!: FormGroup | any;
  @Input() label!: string;
  @Input() items!: Options[];
  @Input() hideSelected: boolean = false;
  @Input() multiple: boolean = false;
  @Input() clearable: boolean = false;
  @Input() addTag: boolean = false;
  @Input() searchable: boolean = true;
  @Input() addTagText: string = "Adicionar novo";
  @Input() showError: boolean = true;

  @Output() onValueChange = new EventEmitter<string>();

  @Output() change = new EventEmitter<any>();

  constructor() { }

  itemsList: Options[] = [];

  ngOnInit(): void {
    this.itemsList = this.items;
    if (this.formGroup.get(this.id)?.value) {
      let list = this.formGroup.get(this.id)?.value;
      if (typeof (list) == "object") {
        let item = list.map((item: any) => item.toString());
        this.formGroup.get(this.id).setValue(item);
      } else {
        let item = this.formGroup.get(this.id).value;
        this.formGroup.get(this.id).setValue(item.toString());
      }
    }
  }

  clickHandle(e: any) {
    if (this.formGroup.get(this.id)?.valid) {
      this.onValueChange.emit(this.formGroup.get(this.id)?.value);
    }
  }

  addTagzinha(e: any) {
    return {
      value: e,
      label: e
    };
  }

  changeHandler(ev: any) {
    this.change.emit(ev.value);
  }

}
